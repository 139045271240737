import { render, staticRenderFns } from "./MyMoveTo.vue?vue&type=template&id=683e9416&scoped=true"
import script from "./MyMoveTo.vue?vue&type=script&lang=js"
export * from "./MyMoveTo.vue?vue&type=script&lang=js"
import style0 from "./MyMoveTo.vue?vue&type=style&index=0&id=683e9416&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683e9416",
  null
  
)

export default component.exports